<template>
  <div class="help app-right-box" ref="help">
    <div class="boxhelp">
      <div class="helpTop">
        <div
          class="btn btn1 pointer"
          @click="topClick(0)"
          :class="topAct == 0 ? 'itemAct' : ''"
        >
        {{ $t('Help.FindCustomer') }}
        </div>
        <el-badge
          :value="feedbackNum"
          is-dot
          :hidden="feedbackNum > 0 ? false : true"
          style="margin-right: 4px"
        >
          <div
            class="btn btn2 pointer"
            @click="topClick(1)"
            :class="topAct == 1 ? 'itemAct' : ''"
          >
          {{ $t('Help.Feedback') }}
          </div>
        </el-badge>
        <div
          class="btn btn1 pointer"
          @click="topClick(item.id)"
          :class="topAct == item.id ? 'itemAct' : ''"
          v-for="(item, index) in topList"
          :key="index"
        >
          {{ $t(item.name) }}
        </div>
      </div>
      <div class="q1" v-if="topAct == 0">
        <div class="hotQuestion">
          <div class="tit">{{ $t('Help.Burning') }}</div>
          <div class="box">
            <div class="box1">
              <div
                class="item pointer"
                v-for="item in questionList.slice(
                  0,
                  Math.ceil(questionList.length / 2)
                )"
                :key="item"
              >
                <div class="item1" @click="goAskdetail(item.id)">
                  <div class="name">
                    {{ item.title }}
                  </div>
                  <div class="right">
                    <div class="rightName">查看</div>
                    <svg-btn
                      class="icon-arrow-right"
                      icon-class="icon-arrow-right"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box1 box2">
              <div
                class="item pointer"
                v-for="item in questionList.slice(
                  Math.ceil(questionList.length / 2),
                  questionList.length
                )"
                :key="item"
              >
                <div class="item1" @click="goAskdetail(item.id)">
                  <div class="name">
                    {{ item.title }}
                  </div>
                  <div class="right">
                    <div class="rightName">查看</div>
                    <svg-btn
                      class="icon-arrow-right"
                      icon-class="icon-arrow-right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="qusetionType">
          <div class="question1" v-show="!questionName">
            <div class="tit">{{ $t('Help.Search') }}</div>
            <div class="box">
              <div
                class="item"
                v-for="(item, index) in cspCategory"
                :key="index"
              >
                <div class="one">{{ item.name }}</div>
                <div
                  class="one"
                  v-for="child in item.child"
                  :key="child.id"
                  @click="getQuestions(child.id, child.name, child)"
                >
                  {{ child.name }}
                </div>
              </div>
              <div class="item">
                <div class="one">其他</div>
                <div class="one" @click="goOtherHelp(1)">
                  《账户注销申请表》
                </div>
                <div class="one" @click="goOtherHelp(2)">《更换手机号申请表》</div>
                <div class="one" @click="goOtherHelp(3)">《侵权投诉》</div>
              </div>
            </div>
          </div>
          <div class="questionList" v-show="questionName">
            <div class="tit">
              以下是 <span class="titAct">{{ questionName }}</span> 的相关问题
              <span class="goback" @click="questionName = false">返回目录</span>
            </div>
            <div class="list">
              <div
                class="item pointer"
                v-for="item in categoryQuestionList"
                :key="item"
                @click="goAskdetail(item.id)"
              >
                {{ item.title }}
              </div>
            </div>
            <fm-button
              class="noQuestion"
              text
              round
              @click="
                routerTo({
                  path: `/submitQuestions?top_tid=${selectChild.pid}&sec_tid=${selectChild.id}`,
                })
              "
              >没找到答案，点此提交问题 ></fm-button
            >
          </div>
        </div>
        <div class="otherQuestionBox" style="display: none;">
          <div class="tit">其他相关问题</div>
          <div class="down">
            <div
              class="item"
              v-for="item in otherQuestionList"
              :key="item.id"
              @click="openNewWin(item)"
            >
              <img class="icon" :src="item.icon" alt="" srcset="" />
              <div class="rightBox">
                <div class="rname">{{ item.name }}</div>
                <div class="rtit pointer">{{ item.tit }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="q1" v-if="topAct == 1" style="margin-top: 16px">
        <template v-if="feedbackList.length > 0">
          <div class="qBox">
            <div
              class="item"
              v-for="item in feedbackList"
              :key="item.id"
              @click="openHistory(item.id)"
            >
              <div class="itemLeft helpBadge">
                <el-badge
                  v-if="item.status == 0"
                  :value="item.new_reply"
                  is-dot
                >
                  <div class="labelType labelType2">待处理</div>
                </el-badge>
                <div v-else-if="item.status == 1" class="labelType labelType3">
                  处理中
                </div>
                <div v-else-if="item.status == 2" class="labelType labelType1">
                  已结束
                </div>
              </div>
              <div class="itemRight">
                <div class="label">
                  {{ item.title }}
                </div>
                <div class="time">{{ item.in_time }}</div>
              </div>
            </div>
            <PaginationVue
              ref="PaginationVue"
              v-if="feedbackTotal > 25"
              :page-size="feedbackQuery.limit"
              :total="feedbackTotal"
              class="pagination"
              @changepage="changepage"
            ></PaginationVue>
          </div>
        </template>

        <template v-if="feedbackTotal > -1 && feedbackList.length == 0">
          <div class="q-empty">
            <div class="q-empty-content">
              <img
                :src="$utils.getPng('web4/jx_default')"
                class="q-empty-img"
                alt=""
              />
              <div class="q-empty-text">{{ $t('Help.Nofeedbackhistory') }}</div>
            </div>
          </div>
        </template>
      </div>

      <div class="q1" v-if="topAct == 27">
        <div class="qusetionType">
          <div class="questionList">
            <div class="tit">{{ $t('Help.Protocol') }}</div>
            <div class="list">
              <div
                class="item pointer"
                v-for="item in categoryQuestionList"
                :key="item"
                @click="goAskdetail(item.id)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="otherQuestionBox" style="display: none;">
          <div class="tit">其他相关问题</div>
          <div class="down">
            <div
              class="item"
              v-for="item in otherQuestionList"
              :key="item.id"
              @click="openNewWin(item)"
            >
              <img class="icon" :src="item.icon" alt="" srcset="" />
              <div class="rightBox">
                <div class="rname">{{ item.name }}</div>
                <div class="rtit pointer">{{ item.tit }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="q1" v-if="topAct == 28">
        <div class="qusetionType">
          <div class="questionList">
            <div class="tit">{{ $t('Help.Announcement') }}</div>
            <div class="list">
              <div
                class="item pointer"
                v-for="item in categoryQuestionList"
                :key="item"
                @click="goAskdetail(item.id)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="otherQuestionBox" style="display: none;">
          <div class="tit">其他相关问题</div>
          <div class="down">
            <div
              class="item"
              v-for="item in otherQuestionList"
              :key="item.id"
              @click="openNewWin(item)"
            >
              <img class="icon" :src="item.icon" alt="" srcset="" />
              <div class="rightBox">
                <div class="rname">{{ item.name }}</div>
                <div class="rtit pointer">{{ item.tit }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SupplementInfo
      :isShow="showSupplementInfo"
      @handleClose="showSupplementInfo = false"
    ></SupplementInfo>
  </div>
</template>

<script>
import PaginationVue from "@/components/Pagination/index.vue";
import SupplementInfo from "@/components/Dialog/SupplementInfo.vue";
import {
  getQuestions,
  getCspCategory,
  getProductionNordNumAPI,
} from "@/utils/apiList/help";
import { getFeedbackList } from "@/utils/apiList/feedbackHistory";
export default {
  components: { PaginationVue, SupplementInfo },
  data() {
    return {
      topList: [
        { id: 27, name: "Help.Protocol" },
        { id: 28, name: "Help.Announcement" },
      ],
      topAct: 0,
      cspCategory: [
        [
          { id: 0, name: "其他" },
          { id: 1, name: "《账户注销申请表》" },
        ],
      ],
      otherQuestionList: [
        {
          id: 0,
          name: "飞猫盘帮助中心",
          tit: "点击进入 >",
          icon: this.$utils.getPng("web4/icon_help1"),
          url: "https://help.feimaoyun.com/feimaoyun",
        },
        {
          id: 1,
          name: "飞猫盘短视频教程",
          tit: "点击进入 >",
          icon: this.$utils.getPng("web4/icon_help2"),
          url: "https://wwwtestjmdyh.fmapp.com/ph/MDAwMDAwMDAwMIast960qb9nfqNxbg",
        },
        {
          id: 0,
          name: "版权投诉&法务相关",
          tit: "《实名举报在线填表》 >",
          icon: this.$utils.getPng("web4/icon_help3"),
          url: "https://jinshuju.net/f/fKYT3q",
        },
      ],
      questionList: [],
      categoryQuestionList: [],
      questionName: "",
      selectChild: {},
      feedbackQuery: {
        page: 1,
        limit: 25,
      },
      feedbackTotal: -1,
      feedbackList: [],
      feedbackNum: 0,
      showSupplementInfo: false,
    };
  },
  created() {
   if (this.$route.query.id) {
      topAct = Number(this.$route.query.id) || 0;
      this.topClick(topAct);
    }
    let topAct = this.$route.params.topAct;
    if (topAct) {
      topAct = Number(topAct) || 0;
      this.topClick(topAct);
    }

    this.getQuestions(0);
    this.getCspCategory();
    this.getProductionNordNum();
  },
  methods: {
    async getFeedbackLIst() {
      try {
        let res = await getFeedbackList(this.feedbackQuery);
        if (res.status == 1) {
          this.feedbackList = res.data.list;
          this.feedbackTotal = Number(res.data.total);
        }
      } catch (e) {}
    },
    topClick(index) {
      this.topAct = index;
      this.feedbackQuery.page = 1;
      if (index == 27 || index == 28) {
        this.getQuestions(index);
      } else if (index == 1) {
        this.getFeedbackLIst();
      }
    },
    changepage(item) {
      this.feedbackQuery.page = item;
      this.$refs.help.scrollTo(0, 0, false);

      this.getFeedbackLIst();
    },
    getQuestions(tid, name, item) {
      this.questionName = name;
      this.selectChild = item;
      getQuestions({ tid: tid }).then((res) => {
        if ((res.status = "1")) {
          if (tid == 0) {
            this.questionList = res.data.list;
          } else {
            this.categoryQuestionList = res.data.list;
          }
        } else {
          this.$toast(res.msg);
        }
      });
    },
    getProductionNordNum() {
      getProductionNordNumAPI().then((res) => {
        if (res.status == "1") {
          this.feedbackNum = res.data.num;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    getCspCategory() {
      getCspCategory().then((res) => {
        if (res.status == "1") {
          this.cspCategory = res.data.top_type;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    openNewWin(item) {
      if (item.id == 1) {
        window.open(item.url + `?platfro=${localStorage.getItem("platfro")}`);
      } else {
        window.open(item.url);
      }
    },
    goAskdetail(id) {
      window.open("/askdetail/" + id);
    },
    routerTo(args) {
      this.$router.push(args);
    },
    openHistory(id) {
      window.open("/feedbackHistory?id=" + id);
    },
    goOtherHelp(type) {
      if (type == 1) {
        if (this.$store.state.userInfo.phone == "") {
          this.showSupplementInfo = true;
          return;
        }
        window.open("/userlogout");
      } else if(type == 2) {
        window.open("/changephone");
      } else {
        window.open("https://jinshuju.net/f/fKYT3q")
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-badge__content.is-dot {
  width: 6px;
  height: 6px;
  right: 4px;
  top: 1px;
}
@import "./index.scss";
</style>
