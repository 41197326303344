<template>
    <el-dialog
      :close-on-click-modal="false"
      top="0vh"
      custom-class="signDialog"
      @close="handleClose()"
      :visible.sync="isShow"
      :append-to-body="true"
      width="788px">
      <div class="payContent" :style="{ backgroundImage: 'url(' + ($store.state.theme == 'light' ? $utils.getPng('popbj') : $utils.getPng('popbj_dark')) + ')', height: type ? '406px' : '454px' }">
        <div class="d-header">
            <div v-show="!type" class="left">
                <span>网页端签到成功</span>
            </div>
            <fm-button type="info" size="mini" text icon="icon-model-close" @click="handleClose"></fm-button>
        </div>
        <div class="cen-box">
            <div v-show="!type" class="ewm-box">
                <div class="title1">
                已获得 <span>{{ addnum }}</span> 福利点奖励
                </div>
                <div class="title2">
                请前往飞猫盘APP-福利社领取
                </div>
                <div class="ewm">
                <img :src="$utils.getPng('downapp')" />
                <div class="text">扫码下载飞猫盘APP</div>
                </div>
            </div>
            <div v-show="type" class="ewm-box2">
                <div class="title2">
                    去飞猫盘APP-福利社领取奖励
                </div>
                <div class="ewm">
                    <img :src="$utils.getPng('downapp')" />
                    <div class="text">扫码下载飞猫盘APP</div>
                </div>
            </div>
            <div class="video" :style="`backgroundImage:url(${video_url ? '' : video_img})`">
                <div class="filter-div" :class="videoIsPlay ? 'filter' : ''">
                    <video
                    class="video-i"
                    ref="myVideo"
                    autoplay
                    muted
                    @ended="videoIsPlay = true"
                    :src="video_url"
                    ></video>
                </div>
                <div class="play" v-show="videoIsPlay" @click="videoPlay">
                    <img :src="$utils.getPng('sign-shua')" />
                </div>
            </div>
        </div>
      </div>
    </el-dialog>
</template>
<script>
export default {
    name: "downloadapp",
    data() {
      return {
        videoIsPlay: false
      };
    },
    props: {
      isShow: Boolean,
      addnum: {
        type: String,
        default: "0"
      },
      video_url: {
        type: String,
        default: ""
      },
      type: {
        type: Boolean,
        default: false
      },
      video_img: {
        type: String,
        default:
          "https://webimgtest.fmapp.com/Public/web/img/webapp/fm2023/welfare/browser_award_info_video_img_v2.jpg"
      }
    },
    created() {},
    methods: {
        videoPlay() {
            this.videoIsPlay = false;
            //触发video的播放事件
            this.$nextTick(()=>{
            this.$refs.myVideo.play();
            })
        },
        handleClose() {
            this.$emit("handleClose");
        }
    }
};
</script>
<style lang="scss" scoped>
:deep(.signDialog.el-dialog .el-dialog__body) {
  padding: 0;
}
.signDialog{
    .d-header{
        .left{
            span{
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
            }
        }
        .fm-button{
            background: transparent;
        }
    }
    .filter {
        filter: blur(4px);
        -webkit-backdrop-filter: blur(4px) !important;
    }
    .filter-div {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        overflow: hidden;
    }
    .payContent {
        width: 788px;
        height: 454px;
        background-size: 100% 100%;
        padding: 24px 0 0 32px;
        box-sizing: border-box;
        .pay-title {
            height: 30px;
            font-size: 20px;
            font-weight: bold;
            line-height: 30px;
        }
        .cen-box {
            display: flex;
            justify-content: flex-end;
            margin-top: 56px;
            .video {
                width: 440px;
                height: 262px;
                border-radius: 16px;
                overflow: hidden;
                margin-right: 44px;
                margin-left: 46px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                position: relative;
                .video-i {
                    width: 100%;
                    height: 100%;
                    border-radius: 16px;
                    object-fit: cover;
                }
                .play {
                    width: 440px;
                    height: 262px;
                    border-radius: 16px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 100;
                    background: rgba(0, 0, 0, 0.3);
                    backdrop-filter: blur(4px);
                    img {
                        width: 28px;
                        height: 28px;
                        cursor: pointer;
                    }
                }
            }
            .ewm-box {
                margin-top: -8px;
                .title1 {
                    height: 24px;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    text-align: center;
                    margin-bottom: 6px;
                    span {
                        color: #f67942;
                    }
                }
                .title2 {
                    font-size: 14px;
                    font-weight: 400;
                  @include useTheme {
                    color: getVar("text-color1-8a8b92");
                  }
                    line-height: 20px;
                }
                .ewm {
                    margin: auto;
                    margin-top: 26px;
                    width: 164px;
                    height: 164px;
                    border-radius: 10px;
                    align-items: center;
                    justify-content: center;
                    background: #ffffff;
                    border: 1px solid #e5e5e5;
                    box-shadow: 0px 5px 37px 2px rgba(0,0,0,0.1);
                    z-index: 100;
                    .text {
                        height: 22px;
                        font-size: 14px;
                        font-weight: 400;
                      @include useTheme {
                        color: getVar("text-color1-8a8b92");
                      }
                        line-height: 22px;
                        text-align: center;
                        margin-top: 16px;
                    }
                    img {
                        width: 158px;
                        height: 158px;
                        margin: 2px 2px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
    .title {
        height: 30px;
        font-size: 20px;
        font-weight: bold;
        line-height: 30px;
        margin-left: 22px;
        margin-top: 24px;
    }
    .ewm-box2{
        margin-top: 6px;
        .title2 {
            height: 24px;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
        }
        .ewm {
            margin: 24px auto;
            width: 164px;
            height: 164px;
            border-radius: 10px;
            align-items: center;
            justify-content: center;
            background: #ffffff;
            border: 1px solid #e5e5e5;
            box-shadow: 0px 5px 37px 2px rgba(0,0,0,0.1);
            z-index: 100;
            .text {
            height: 22px;
            font-size: 14px;
            font-weight: 400;
              @include useTheme {
                color: getVar("text-color1-8a8b92");
              }
            line-height: 22px;
            text-align: center;
            margin-top: 16px;
            }
            img {
            width: 158px;
            height: 158px;
            margin: 2px 2px;
            border-radius: 10px;
            }
        }
    }
}
</style>
