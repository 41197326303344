<template>
  <!-- 消息提示组件 -->
  <div>
    <div
      v-for="(item, index) in toastList"
      :key="index"
      class="toastShow"
      v-show="item.showtoast"
      :class="item.showtoast ? 'play-first' : ''"
      :style="'top:' + item.top + 'px'"
    >
      <img class="tsicon" :src="$utils.getPng('toast_icon' + state)" />
      <!-- 2warning 3error 4info-->
      <div class="tstit">{{ showtit }}{{ item.top }}</div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  data() {
    return {
      showtit: "新手机验证码错误，请重试",
      state: 1,
      time: "",
      top: 40,
      toastList: [],
    };
  },
  watch: {
    toastList: function (newVal, oldVal) {
      for (let index = 0; index < newVal.length; index++) {
        if (newVal[index].showtoast) {
          this.time = setTimeout(() => {
            this.toastList[index].showtoast = false;
            this.toastList[index + 1].top = this.toastList[index + 1].top - 60;
          }, 3000);
        }
      }
    },
  },
  methods: {
    showToastfun: debounce(function (tit, state = 2) {
      if (tit) {
        console.log(state);
        console.log(state == 1
              ? "success"
              : state == 2
              ? "warning"
              : state == 3
              ? "error"
              : "info",);
        
        this.$message({
          type:
            state == 1
              ? "success"
              : state == 2
              ? "warning"
              : state == 3
              ? "error"
              : "info",
          message: tit,
        });
      }
    }, 200),
  },
};
</script>

<style lang="scss" scoped>
.play-first {
  animation: myfirst 2s;
}
@keyframes myfirst {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.toastShow {
  width: 380px;
  height: 48px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 6px;
  border: 1px solid #efefef;
  position: fixed;
  // top: 135px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: left;
  align-items: center;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e5e5;
  opacity: 0;
  padding: 0 20px;
  overflow: hidden;
  z-index: 2147483647;

  .tsicon {
    width: 16px;
    height: 16px;
  }
  .tstit {
    font-size: 14px;
    font-weight: 400;
    color: #8a8b92;
    margin-left: 11px;
  }
}
</style>
